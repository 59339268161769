<template>
  <portal>
    <div class="gallery-mobile-component">
      <div class="gallery-mobile-component__header">
        <div class="gallery-mobile-component__close" @click="close">
          <img
            src="~/assets/img/svg/common/close.svg"
            alt="закрыть"
          />
        </div>
      </div>
      <div
        ref="refImages"
        class="gallery-mobile-component__images"
      >
        <suspense>
          <nuxt-img
            v-for="(item, index) in images"
            :key="`index-${ index }`"
            :src="String(item)"
            width="640"
            fit="contain"
            loading="lazy"
            @load="(event) => eventLoadImage(item, index, event)"
            :alt="`gallery-mobile-image-${item}-index-${index}`"
          />
        </suspense>

        <iframe
          v-for="(video, index) in files"
          :key="`video-${ index }`"
          :src="prepareVideoUrl(String(video.url))"
          allowfullscreen
          frameborder="0"
          width="100%"
          height="100%"
          allow="autoplay"
          encrypted-media
          picture-in-picture
        />

        <suspense>
          <div v-for="(item, index) in userImagesIds" style="position:relative;">
            <nuxt-img
              :key="`index-${ index }`"
              :src="String(item.url)"
              width="640"
              fit="contain"
              loading="lazy"
              :alt="`gallery-mobile-image-${item}-index-${index}`"
            />
            <div v-if="item.hint" class="collection-slider-images__slide-hint">
              {{ item.hint }}
            </div>
          </div>
        </suspense>
      </div>
    </div>
  </portal>
</template>

<script setup lang="ts">
import useGalleryComponent from '~/composables/useGallery';
import {computed, type ComputedRef} from 'vue';

const galleryComponent = useGalleryComponent();
const images: ComputedRef<any[]> = computed(() => {
  return galleryComponent?.galleryState?.value?.imagesIds || []
});
const files: ComputedRef<any[]> = computed(() => {
  return galleryComponent?.galleryState?.value?.videos || []
});
const userImagesIds: ComputedRef<any[]> = computed(() => {
  const _product: any = galleryComponent?.galleryState.value?.product;
  const _products: any = galleryComponent?.galleryState.value?.products;
  const userImagesIds = [...(_products?.dynamicFields?.user_images_ids || _product?.dynamicFields?.user_images_ids || [])];
  if (userImagesIds.length <= 0) {
    return []
  }

  return  userImagesIds.map((imageId: number) => {
    return {
      url: String(imageId),
      hint: "Фото от клиентов",
    }
  })
});

const close = () => {
  galleryComponent.closeGallery();
};

const refImages: any = ref(null);
const eventLoadImage = (item: any, index: number, event: any) => {
  // if ((galleryComponent?.galleryState?.value?.indexActiveSlide || 0) <= 0) {
  //   return
  // }
  // if ((galleryComponent?.galleryState?.value?.indexActiveSlide || 0) !== index) {
  //   return;
  // }
  //
  // const boundingClientRect = event.target.getBoundingClientRect();
  // const topPosition = boundingClientRect.top - boundingClientRect.height - 10;
  // refImages.value.scrollBy({
  //   top: topPosition,
  //   behavior:"smooth"
  // });
  // console.log('boundingClientRect.top - boundingClientRect.height - 10: ', boundingClientRect.top - boundingClientRect.height - 10);
  // console.log('event: ', boundingClientRect);
};

// логика для формирования ссылки iframe
const prepareVideoUrl = function(url: string) {
  let videoUrl = '';
  if (url.includes('vk.com')) {
    const videoIds = url.split('-')?.[1]
    const videoOid = videoIds.split('_')?.[0]
    const videoId = videoIds.split('_')?.[1]

    videoUrl = `https://vk.com/video_ext.php?oid=-${videoOid}&id=${videoId}&autoplay=0&loop=1&volume=0`
  }
  if (url.includes('youtube')) {
    videoUrl = url
  }
  return videoUrl;
}

onMounted(() => {
  document.body.style.overflow = 'hidden';
});
onBeforeUnmount(() => {
  document.body.style.overflow = '';
});
</script>

<style lang="scss">
.gallery-mobile-component {
  position: fixed;
  z-index: 999;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: white;
}
.gallery-mobile-component__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  position: absolute;
  top: 0; left: 0; right: 0;
  background: white;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
}
.gallery-mobile-component__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  img {
    //filter: invert(1);
  }
}
.gallery-mobile-component__images {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  padding-top: 50px;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;

  .collection-slider-images__slide-hint {
    top: 4px; left: 4px;
    padding: 8px 12px;
    border-radius: 4px;
  }
}
</style>
